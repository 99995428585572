import React, { useState, useEffect } from "react";

const MatchTabs = ({ matches, renderMatchCard }) => {
  const [activeTab, setActiveTab] = useState("QF");
  const [filteredMatches, setFilteredMatches] = useState([]);

  useEffect(() => {
    console.log("All matches:", matches);
    filterMatches(activeTab.toUpperCase());
  }, [matches, activeTab]);

  const filterMatches = (matchType) => {
    const filtered = matches.filter((match) => {
      const matchName = match.matchName || "";
      return matchName.toUpperCase().includes(matchType);
    });
    console.log(`Filtered ${matchType} matches:`, filtered);
    setFilteredMatches(filtered);
  };

  const renderMatches = () => {
    if (filteredMatches.length === 0) {
      return (
        <div className="text-gray-400">No matches found for this stage.</div>
      );
    }
    return filteredMatches.map((match, index) => renderMatchCard(match, index));
  };

  const tabs = [
    { id: "QF", label: "Quarter Finals" },
    { id: "SF", label: "Semifinals" },
    { id: "Final", label: "Final" },
  ];

  return (
    <div>
      <div className="flex border-b border-gray-700">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`px-4 py-2 w-full text-sm font-medium ${
              activeTab === tab.id
                ? "border-b-2 border-blue-500 text-blue-400"
                : "text-gray-400 hover:text-blue-400"
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="mt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {renderMatches()}
        </div>
      </div>
    </div>
  );
};

export default MatchTabs;
