import React from "react";

const UpdateScorePopup = ({
  show,
  onClose,
  match,
  homeScore,
  setHomeScore,
  awayScore,
  setAwayScore,
  onUpdateScore,
}) => {
  if (!show || !match) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 overflow-y-auto h-full w-full flex items-center justify-center px-4">
      <div className="bg-gray-800 rounded-lg shadow-xl p-6 w-full max-w-md text-gray-100">
        <h3 className="text-xl font-semibold mb-4">Update Score</h3>
        <p className="text-sm text-gray-400 mb-4">
          {match.matchDay} - {match.matchName}
        </p>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="text-sm font-medium w-1/3">
              {match.homeTeam}
            </label>
            <input
              type="number"
              value={homeScore}
              onChange={(e) => setHomeScore(e.target.value)}
              className="w-20 p-2 border rounded-md text-center bg-gray-700 text-white border-gray-600"
              min={0}
            />
          </div>
          <div className="flex items-center justify-between">
            <label className="text-sm font-medium w-1/3">
              {match.awayTeam}
            </label>
            <input
              type="number"
              value={awayScore}
              onChange={(e) => setAwayScore(e.target.value)}
              className="w-20 p-2 border rounded-md text-center bg-gray-700 text-white border-gray-600"
              min={0}
            />
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-600 text-gray-100 rounded-md hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={onUpdateScore}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Update Score
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateScorePopup;
