import React from "react";

const Legal = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl sm:text-3xl font-bold mt-8 mb-4">
        Soccerbro - Track your tournaments
      </h1>
      <h3 className="text-xl sm:text-3xl font-bold mb-4">Privacy Policy</h3>
      <p>
        We value your privacy and are committed to protecting your personal
        information. When you log in with your email, we only collect your name
        and email address to authorize your access and display this information
        on our site. Your data is not shared with anyone or sent anywhere else.
        If you ever want to delete your information from our system, just send
        us an email at <u>kawsar.ahmed042@gmail.com</u>, and we’ll take care of
        it.
      </p>

      <h3 className="text-xl sm:text-3xl font-bold mt-8 mb-4">
        Terms of Service
      </h3>
      <p>
        By using our site, you agree that we can use your email and name to
        provide you with access to our services and display your name on the
        interface. We do not share or send your data to any third parties. If
        you wish to remove your information, please email us at
        <u> kawsar.ahmed042@gmail.com</u>, and we will delete your data from our
        system promptly.
      </p>

      <p>
        Updated <strong>28th August, 2024</strong>
      </p>
    </div>
  );
};

export default Legal;
