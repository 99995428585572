import React, { useEffect, useState } from "react";
import ShowPassword from "./show-pass.svg";
import HidePassword from "./hide-pass.svg";
import GoogleIcon from "./google.svg";
import supabase from "./supabaseClient";
import ReactGA from "react-ga4";

const Auth = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    if (isLogin) {
      ReactGA.send("pageview", "/login");
    } else {
      ReactGA.send("pageview", "/signup");
    }

    const handleAuthRedirect = async () => {
      const hashParams = new URLSearchParams(window.location.hash.slice(1));
      const accessToken = hashParams.get("access_token");
      const refreshToken = hashParams.get("refresh_token");

      if (accessToken && refreshToken) {
        try {
          const { data, error } = await supabase.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken,
          });

          if (error) throw error;

          if (data.user) {
            await handleSuccessfulAuth(data.user);
          }
        } catch (error) {
          console.error("Error setting session:", error);
          setError(error.message);
        }

        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
    };

    handleAuthRedirect();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === "SIGNED_IN" && session) {
          console.log("User signed in:", session.user);
          await handleSuccessfulAuth(session.user);
        }
      }
    );

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, [isLogin]);

  const handleSuccessfulAuth = async (user) => {
    onLogin(user);
    ReactGA.event({
      category: "Authentication",
      action: "Successful Login",
      label: user.email,
    });
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    try {
      let data, error;
      if (isLogin) {
        ({ data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
        }));

        ReactGA.event({
          category: "Authentication",
          action: "Login Attempt",
        });
      } else {
        ({ data, error } = await supabase.auth.signUp({
          email,
          password,
        }));

        ReactGA.event({
          category: "Authentication",
          action: "Signup Attempt",
        });
      }

      if (error) throw error;
      if (data?.user) {
        await handleSuccessfulAuth(data.user);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: "https://soccerbro.xyz/dashboard", // Always use production URL
        },
      });

      ReactGA.event({
        category: "Authentication",
        action: "Google Sign In Attempt",
      });

      if (error) throw error;
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md">
        <h4 className="text-2xl font-bold mb-6 text-center text-white">
          Soccer Bro - {isLogin ? "Login" : "Sign Up"}
        </h4>
        {error && <p className="text-red-400 text-center mb-4">{error}</p>}
        <form onSubmit={handleAuth}>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Email</label>
            <input
              type="email"
              className="w-full p-2 border rounded bg-gray-700 text-white border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full p-2 border rounded pr-10 bg-gray-700 text-white border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                <img
                  src={showPassword ? ShowPassword : HidePassword}
                  alt={showPassword ? "Hide Password" : "Show Password"}
                  className="w-5 h-5 mr-3 flex-shrink-0 filter invert"
                />
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition duration-200"
          >
            {isLogin ? "Login" : "Sign Up"}
          </button>
        </form>

        <button
          type="button"
          onClick={handleGoogleSignIn}
          className="w-full bg-gray-700 text-white py-2 rounded hover:bg-gray-600 mt-4 flex items-center justify-center transition duration-200"
        >
          <img src={GoogleIcon} alt="Google Icon" className="w-5 h-5 mr-3" />
          <span>Sign in with Google</span>
        </button>

        <p className="text-center mt-4 text-gray-300">
          {isLogin ? (
            <>
              Don't have an account?{" "}
              <button
                onClick={() => setIsLogin(false)}
                className="text-blue-400 hover:underline"
              >
                Sign Up
              </button>
            </>
          ) : (
            <>
              Already have an account?{" "}
              <button
                onClick={() => setIsLogin(true)}
                className="text-blue-400 hover:underline"
              >
                Login
              </button>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default Auth;
