import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import HomePage from "./Homepage";
import Dashboard from "./Dashboard";
import PublicTournament from "./TournamentPublicView";
import Legal from "./Legal";
import Layout from "./Layout"; // Import the new Layout component
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

import {
  RouterProvider,
  Route,
  RootRoute,
  createRouter,
  Outlet,
} from "@tanstack/react-router";

const TRACKING_ID = "G-GHNCCHDJGC";
ReactGA.initialize(TRACKING_ID);

// Modify the root route to use the Layout component
const rootRoute = new RootRoute({
  component: () => (
    <Layout>
      <Outlet />
    </Layout>
  ),
});

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  component: HomePage,
});

const dashboardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/dashboard",
  component: Dashboard,
});

const tournamentsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/t",
  component: PublicTournament,
});

const legalRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/privacy-tos",
  component: Legal,
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  dashboardRoute,
  tournamentsRoute,
  legalRoute,
]);

const router = new createRouter({ routeTree });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
