import React, { useState, useEffect } from "react";
import Login from "./Login";
import App from "./App";
import supabase from "./supabaseClient";

const Dashboard = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const { subscription } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (session) {
          setUser(session.user);
        } else {
          setUser(null);
        }
      }
    );

    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
    };

    fetchUser();

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  if (!user) {
    return <Login onLogin={(user) => setUser(user)} />;
  }

  return <App user={user} />;
};

export default Dashboard;
