import React from "react";

const MatchCard = ({ match, onSelect, hideUpdateButton = false }) => {
  const getInitials = (name) => name.slice(0, 1).toUpperCase();

  return (
    <div
      key={match.id}
      className="bg-gray-800 rounded-lg shadow-md p-6 mb-6 flex items-center justify-between"
    >
      <div className="flex items-center space-x-4">
        <div className="flex flex-col items-center">
          <div className="w-12 h-12 rounded-full bg-gray-700 flex items-center justify-center text-xl font-bold mb-1 text-white">
            {getInitials(match.homeTeam)}
          </div>
          <span className="text-sm text-center font-bold text-gray-300">
            {match.homeTeam}
          </span>
        </div>
        <div className="flex flex-col items-center">
          {match.completedAt ? (
            <div className="text-3xl font-bold text-white">
              {match.homeScore} - {match.awayScore}
            </div>
          ) : (
            <span className="text-lg text-gray-400">vs</span>
          )}
        </div>
        <div className="flex flex-col items-center">
          <div className="w-12 h-12 rounded-full bg-gray-700 flex items-center justify-center text-xl font-bold mb-1 text-white">
            {getInitials(match.awayTeam)}
          </div>
          <span className="text-sm text-center font-bold text-gray-300">
            {match.awayTeam}
          </span>
        </div>
      </div>
      {!hideUpdateButton && (
        <div className="flex-shrink-0 ml-auto">
          <button
            onClick={() => onSelect(match)}
            className={`${
              match.completedAt
                ? "bg-transparent text-blue-400 font-semibold py-2 px-4 hover:text-blue-300"
                : "bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded-full"
            }`}
            style={
              match.completedAt
                ? { textDecoration: "underline", cursor: "pointer" }
                : {}
            }
          >
            {match.completedAt ? "Update Score" : "Save Score"}
          </button>
        </div>
      )}
    </div>
  );
};

export default MatchCard;
