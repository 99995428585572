import React, { useState, useEffect } from "react";
import { computeStandings } from "./utils";

const LeagueTournamentTab = ({ matches, renderMatchCard, teams }) => {
  const [standings, setStandings] = useState([]);
  const [activeTab, setActiveTab] = useState("matches");

  useEffect(() => {
    if (!teams || !matches || teams.length === 0) return;
    console.log(">> computing > ", { teams, matches });
    const updatedStandings = computeStandings(matches);
    setStandings(updatedStandings);
  }, [teams, matches]);

  if (!matches || !teams) {
    return <div className="text-gray-400">Loading...</div>;
  }

  return (
    <div className="bg-gray-800 rounded-lg p-4">
      <div className="flex border-b border-gray-700 mb-4">
        <button
          className={`px-4 py-2 w-full text-sm font-medium ${
            activeTab === "matches"
              ? "border-b-2 border-blue-500 text-blue-400"
              : "text-gray-400 hover:text-blue-400"
          }`}
          onClick={() => setActiveTab("matches")}
        >
          Matches
        </button>
        <button
          className={`px-4 py-2 w-full text-sm font-medium ${
            activeTab === "standings"
              ? "border-b-2 border-blue-500 text-blue-400"
              : "text-gray-400 hover:text-blue-400"
          }`}
          onClick={() => setActiveTab("standings")}
        >
          Standings
        </button>
      </div>

      {activeTab === "matches" && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {matches.map(renderMatchCard)}
        </div>
      )}

      {activeTab === "standings" && (
        <div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-900 shadow-md rounded-lg">
              <thead className="bg-gray-800">
                <tr>
                  <th className="py-2 px-4 text-left text-gray-300">Rank</th>
                  <th className="py-2 px-4 text-left text-gray-300">Team</th>
                  <th className="py-2 px-4 text-center text-gray-300">P</th>
                  <th className="py-2 px-4 text-center text-gray-300">W</th>
                  <th className="py-2 px-4 text-center text-gray-300">D</th>
                  <th className="py-2 px-4 text-center text-gray-300">L</th>
                  <th className="py-2 px-4 text-center text-gray-300">GF</th>
                  <th className="py-2 px-4 text-center text-gray-300">GA</th>
                  <th className="py-2 px-4 text-center text-gray-300">GD</th>
                  <th className="py-2 px-4 text-center text-gray-300">Pts</th>
                </tr>
              </thead>
              <tbody>
                {standings.map((team, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-800" : "bg-gray-700"
                    } hover:bg-gray-600`}
                  >
                    <td className="py-2 px-4 text-left font-semibold text-gray-300">
                      {index + 1}
                    </td>
                    <td className="py-2 px-4 text-gray-300">{team.team}</td>
                    <td className="py-2 px-4 text-center text-gray-300">
                      {team.played}
                    </td>
                    <td className="py-2 px-4 text-center text-gray-300">
                      {team.won}
                    </td>
                    <td className="py-2 px-4 text-center text-gray-300">
                      {team.drawn}
                    </td>
                    <td className="py-2 px-4 text-center text-gray-300">
                      {team.lost}
                    </td>
                    <td className="py-2 px-4 text-center text-gray-300">
                      {team.gf}
                    </td>
                    <td className="py-2 px-4 text-center text-gray-300">
                      {team.ga}
                    </td>
                    <td className="py-2 px-4 text-center text-gray-300">
                      {team.gd}
                    </td>
                    <td className="py-2 px-4 text-center font-bold text-white">
                      {team.points}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeagueTournamentTab;
