import React from "react";
import { Link } from "@tanstack/react-router";
import { User, Trophy, AlertCircle } from "lucide-react";

// Import images
import HeroImage from "./hero.png";

const HERO_TITLE = "Make Your FC Tournaments Count";
const HERO_SUBTITLE =
  "Join SoccerBro and easily manage, track, and share your friendly tournaments with friends.";
const KEY_FEATURES_TITLE = "Why SoccerBro?";
const KEY_FEATURES_DESCRIPTION =
  "SoccerBro is designed to fill the gap of your game's tournament management, giving you the tools to create and track your own competitions for online.";
const COMMUNITY_SECTION_TITLE = "Be Part of the SoccerBro Community";

const features = [
  {
    title: "Create and Manage Tournaments",
    description:
      "Set up your own football tournaments with custom rules and keep track of every match effortlessly.",
  },
  {
    title: "Track Match Scores",
    description:
      "Record and update match scores in real-time and keep a detailed history of all your games.",
  },
  {
    title: "Share Tournament Stats Publicly",
    description:
      "Generate shareable links for your tournaments so others can view scores, stats, and standings.",
  },
];

const CheckIcon = () => (
  <svg
    className="w-6 h-6 text-green-500 mr-2"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 13l4 4L19 7"
    />
  </svg>
);

const TestimonialCard = ({ quote, author }) => (
  <div className="bg-gray-700 rounded-lg p-6 shadow-lg flex flex-col justify-between">
    <div>
      <User className="text-blue-400 mb-4" size={32} />
      <p className="text-gray-300 italic mb-4">"{quote}"</p>
    </div>
    <p className="text-gray-400 font-semibold">{author}</p>
  </div>
);

const StatCard = ({ number, text }) => (
  <div className="bg-gray-700 rounded-lg p-6 shadow-lg flex flex-col items-center justify-center text-center">
    <Trophy className="text-yellow-400 mb-4" size={48} />
    <span className="text-4xl font-bold text-white mb-2">{number}</span>
    <p className="text-gray-300">{text}</p>
  </div>
);

const UpcomingFeatureCard = ({ title, description }) => (
  <div className="bg-gray-700 rounded-lg p-6 shadow-lg flex flex-col items-start">
    <AlertCircle className="text-blue-400 mb-4" size={32} />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

const HomePage = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      {/* Navbar */}
      <nav className="flex justify-between items-center p-4">
        <div className="text-2xl font-bold">SoccerBro</div>
        <div>
          <Link
            to="/dashboard"
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          >
            Join
          </Link>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="container mx-auto px-4 py-12">
        <div className="bg-gray-800 rounded-lg p-8 flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-4xl font-bold mb-4">{HERO_TITLE}</h1>
            <p className="mb-6">{HERO_SUBTITLE}</p>
            <Link
              to="/dashboard"
              className="bg-blue-500 text-white px-6 py-3 rounded-full text-lg"
            >
              Get Started Free
            </Link>
          </div>
          <div className="md:w-1/2">
            <img src={HeroImage} alt="players" className="rounded-lg" />
          </div>
        </div>
      </div>

      {/* Key Features Section */}
      <div className="bg-gray-800 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-4">{KEY_FEATURES_TITLE}</h2>
          <p className="mb-8">{KEY_FEATURES_DESCRIPTION}</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-700 p-6 rounded-lg">
                <div className="flex items-center mb-2">
                  <CheckIcon />
                  <h3 className="text-xl font-semibold">{feature.title}</h3>
                </div>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Community Section */}
      <div className="container mx-auto px-4 py-12">
        <h2 className="text-3xl font-bold mb-8">{COMMUNITY_SECTION_TITLE}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <TestimonialCard
            quote="One of best memories I have is with my pals playing together. Now I'll be able to keep track of these!"
            author="Reddit user"
          />
          <StatCard number="20+" text="Tournaments Created" />
          <TestimonialCard
            quote="Finally we can have our own tournaments which is still missing on FC. Thanks!"
            author="Reddit user"
          />
          <UpcomingFeatureCard
            title="Feature Request"
            description="Google Login launched in September"
          />
        </div>
      </div>
      <footer className="bg-gray-800 py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <p className="text-gray-400">
                © 2024 SoccerBro. All rights reserved.
              </p>
            </div>
            <div className="flex flex-col md:flex-row items-center">
              <Link
                to="/privacy-tos"
                className="text-blue-400 hover:text-blue-300 mb-2 md:mb-0 md:mr-4"
              >
                Privacy Policy & Terms of Service
              </Link>
              <p className="text-gray-400">
                Support:{" "}
                <a
                  href="mailto:kawsar.ahmed042@gmail.com"
                  className="text-blue-400 hover:text-blue-300"
                >
                  kawsar.ahmed042@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
