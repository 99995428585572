import React, { useState, useEffect } from "react";
import supabase from "./supabaseClient";

import {
  sortMatches,
  handleKnockoutScoreUpdate,
  handleLeagueScoreUpdate,
} from "./utils";
import CreateTournamentPopup from "./CreateTournament";
import UpdateScorePopup from "./UpdateScore";
import Notification from "./Notification";
import Footballl from "./football.svg";
import Shoe from "./shoe.svg";
import Cup from "./cup.svg";
import MatchTabs from "./MatchTab";
import {
  getMatchesFromSupabase,
  getTournamentsFromSupabase,
  setMatchResult,
} from "./supabaseFunctions";
import LeagueTournamentTab from "./LeagueTournamentTab";
import MatchCard from "./MatchCard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyIcon from "./copy.svg";
import ReactGA from "react-ga4";

const App = ({ user }) => {
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [matches, setMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [homeScore, setHomeScore] = useState("");
  const [awayScore, setAwayScore] = useState("");
  const [showCreateTournamentPopup, setShowCreateTournamentPopup] =
    useState(false);
  const [notification, setNotification] = useState(null);
  const [loadingTournaments, setLoadingTournaments] = useState(false);
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [noTournamentsFound, setNoTournamentsFound] = useState(false);
  const [noMatchesFound, setNoMatchesFound] = useState(false);

  const baseUrl = window.location.origin;

  const showNotification = (message, type = "success") => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 2000);
  };

  useEffect(() => {
    const fetchTournaments = async () => {
      if (user) {
        try {
          setLoadingTournaments(true);
          const tournamentsList = await getTournamentsFromSupabase(user.id);
          setTournaments(tournamentsList);

          const storedTournamentId = localStorage.getItem(
            "selectedTournamentId"
          );
          if (storedTournamentId) {
            const storedTournament = tournamentsList.find(
              (t) => t.id === storedTournamentId
            );
            if (storedTournament) {
              setSelectedTournament(storedTournament);
            } else if (tournamentsList.length > 0) {
              setSelectedTournament(
                tournamentsList[tournamentsList.length - 1]
              );
            }
          } else if (tournamentsList.length > 0) {
            setSelectedTournament(tournamentsList[tournamentsList.length - 1]);
          }

          setNoTournamentsFound(tournamentsList.length === 0);
        } catch (error) {
          showNotification(error.message, "error");
        } finally {
          setLoadingTournaments(false);
        }
      }
    };

    fetchTournaments();
  }, [user]);

  useEffect(() => {
    const fetchMatches = async () => {
      if (selectedTournament) {
        try {
          setLoadingMatches(true);
          setMatches([]); // Clear previous matches
          const fetchedMatches = await getMatchesFromSupabase(
            selectedTournament.id
          );
          const sortedMatches = sortMatches(fetchedMatches);
          setMatches(sortedMatches);
          setNoMatchesFound(sortedMatches.length === 0);
        } catch (error) {
          showNotification(error.message, "error");
        } finally {
          setLoadingMatches(false);
        }
      }
    };

    if (selectedTournament) {
      fetchMatches();
      localStorage.setItem("selectedTournamentId", selectedTournament.id);
    }
  }, [selectedTournament]);

  const handleCreateTournament = () => {
    setShowCreateTournamentPopup(true);
  };

  const handleSelectTournament = (tournament) => {
    setSelectedTournament(tournament);
    localStorage.setItem("selectedTournamentId", tournament.id);
  };

  const handleSelectMatch = (match) => {
    setSelectedMatch(match);
    setHomeScore("");
    setAwayScore("");
  };

  const handleUpdateScore = async () => {
    try {
      if (Number(homeScore) < 0 || Number(awayScore) < 0) {
        throw new Error("Score cannot be less than 0");
      }

      if (Number(homeScore) > 50 || Number(awayScore) > 50) {
        throw new Error("Unrealistic score given");
      }

      if (selectedMatch && homeScore !== "" && awayScore !== "") {
        const completedAt = new Date().toISOString();
        console.log(
          "updating score for ",
          selectedMatch.matchDay,
          selectedMatch.matchName
        );

        // Update the match result in the database
        await setMatchResult({
          matchId: selectedMatch.id,
          homeScore: Number(homeScore),
          awayScore: Number(awayScore),
          completedAt,
        });

        ReactGA.event({
          category: "Match",
          action: "Score_Updated",
        });

        // Update the match result in the local state
        const updatedMatches = matches.map((match) =>
          match.id === selectedMatch.id
            ? { ...match, homeScore, awayScore, completedAt }
            : match
        );

        const sortedMatches = sortMatches(updatedMatches);
        setMatches(sortedMatches);
        setSelectedMatch(null);
        setHomeScore("");
        setAwayScore("");

        // Handle post-match updates based on tournament type
        if (selectedTournament.tournamentType === "knockout_quarter_final") {
          await handleKnockoutScoreUpdate({
            selectedTournament,
            selectedMatch,
            matches: sortedMatches,
            homeScore,
            awayScore,
          });
        } else if (selectedTournament.tournamentType === "league") {
          const allMatchFinished = updatedMatches.every(
            (m) => m.completedAt !== null
          );
          if (allMatchFinished) {
            console.log(
              "Every match finished for tournament. calculating winner",
              selectedTournament.id,
              updatedMatches,
              selectedTournament.teams
            );
            await handleLeagueScoreUpdate(
              selectedTournament.id,
              updatedMatches,
              selectedTournament.teams
            );
            showNotification("Tournament updated with winner");
          }
        }
      }
    } catch (error) {
      console.error("Failed to update score:", error);
      ReactGA.event({
        category: "Match",
        action: "Score_Update_Failed",
        label: JSON.stringify({
          matchId: selectedMatch.id,
          homeScore: Number(homeScore),
          awayScore: Number(awayScore),
        }),
      });
      showNotification(error.message, "error");
    }
  };

  const renderMatchCard = (match) => (
    <MatchCard key={match.id} match={match} onSelect={handleSelectMatch} />
  );

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error("Error logging out:", error);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto p-4">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <img
              src={Footballl}
              alt="Football Icon"
              className="w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
            />
            <h1 className="text-2xl sm:text-3xl font-bold">SOCCER BRO</h1>
          </div>
          <button
            onClick={handleLogout}
            className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-200"
          >
            Logout
          </button>
        </div>

        {notification && (
          <Notification
            message={notification.message}
            type={notification.type}
          />
        )}

        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="w-full lg:w-1/4">
            <div className="bg-gray-800 rounded-lg p-4 shadow-lg">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Tournaments</h2>
                <button
                  onClick={handleCreateTournament}
                  className="bg-blue-600 hover:bg-blue-700 text-white rounded-full w-8 h-8 flex items-center justify-center transition duration-200"
                >
                  +
                </button>
              </div>

              {loadingTournaments ? (
                <div className="text-gray-400">Loading tournaments...</div>
              ) : noTournamentsFound ? (
                <div className="text-gray-400">
                  No tournament found, create one!
                </div>
              ) : (
                <ul className="space-y-2">
                  {tournaments.map((tournament, index) => (
                    <li
                      key={index}
                      className={`flex items-center p-3 rounded-md cursor-pointer transition duration-200 ${
                        selectedTournament === tournament
                          ? "bg-gray-700"
                          : "hover:bg-gray-700"
                      }`}
                      onClick={() => handleSelectTournament(tournament)}
                    >
                      <img
                        src={tournament.winner ? Cup : Shoe}
                        alt="Tournament Icon"
                        className="w-5 h-5 mr-3 flex-shrink-0"
                      />
                      <div className="flex-grow overflow-hidden">
                        <span className="truncate block text-gray-200">
                          {tournament.name}
                        </span>
                        {tournament.winner && (
                          <span className="text-sm text-gray-400 truncate block">
                            Winner: {tournament.winner}
                          </span>
                        )}
                      </div>
                      <CopyToClipboard
                        text={`${baseUrl}/t?id=${tournament.id}`}
                        onCopy={() =>
                          showNotification("Link copied to clipboard")
                        }
                      >
                        <button className="ml-3 p-1 hover:bg-gray-600 rounded transition duration-200">
                          <img
                            src={CopyIcon}
                            alt="Copy Icon"
                            className="w-5 h-5 flex-shrink-0"
                          />
                        </button>
                      </CopyToClipboard>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="w-full lg:w-3/4">
            {loadingMatches ? (
              <div className="text-center text-gray-400">
                Loading matches...
              </div>
            ) : noMatchesFound ? (
              <div className="text-center text-gray-400">No matches found</div>
            ) : (
              selectedTournament && (
                <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
                  <h2 className="text-2xl font-semibold mb-6">
                    {selectedTournament.name}
                  </h2>

                  <div className="space-y-6">
                    {selectedTournament?.tournamentType === "league" ? (
                      <LeagueTournamentTab
                        matches={matches}
                        renderMatchCard={renderMatchCard}
                        teams={selectedTournament?.teams}
                      />
                    ) : (
                      <MatchTabs
                        matches={matches}
                        renderMatchCard={renderMatchCard}
                      />
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        <CreateTournamentPopup
          show={showCreateTournamentPopup}
          onClose={() => setShowCreateTournamentPopup(false)}
          setTournaments={setTournaments}
          setSelectedTournament={setSelectedTournament}
        />

        <UpdateScorePopup
          show={!!selectedMatch}
          onClose={() => setSelectedMatch(null)}
          match={selectedMatch}
          homeScore={homeScore}
          setHomeScore={setHomeScore}
          awayScore={awayScore}
          setAwayScore={setAwayScore}
          onUpdateScore={handleUpdateScore}
        />
      </div>
    </div>
  );
};

export default App;
